import React, { useState, useEffect } from "react";
import styles from "../components/tradeall.module.css"
import axios from "axios";

const Table = ({ optionvalue, maindata, hideEstimate , selectedCountry , selectedImpacts }) => {
  // const countrydata = ['UK', 'US', 'JP', 'AU'];
  const [matcheduserdata , setMatcheduserdata]= useState([]);

const handleEventClick = async (item) => {
    let eventName;
    let eventcountry = item.country;
    let eventimpact = item.impact;
    let eventdate = item.date;
    let eventtime = item.time;
    let timeclickedurl = 1 ;
    // if (item.event.includes("(")) {
    //   eventName = item.event.substring(0, item.event.indexOf("(")).trim();
    // } else {
    //   eventName = item.event;
    // }

    
    const cleanEventName = (event) => {
     
      const monthPattern = /\((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)(\/\d+)?\)/g;
      
      const cleanedEvent = event
          .replace(monthPattern, "") // Remove parentheses with month names and optional numbers like (aug/11)
          .replace(/\(\s*\)/g, "") 
          .trim();
      return cleanedEvent;
  };

  // Check if event contains parentheses and clean it
  if (item.event.includes("(")) {
      eventName = cleanEventName(item.event);
  } else {
      eventName = item.event;
  }

  console.log("eventName" , eventName)
    if (eventcountry === "UK" || eventcountry === "US" || eventcountry === "CA" ||
    eventcountry === "CH" || eventcountry === "EU" || eventcountry === "JP" ||
    eventcountry === "AU" || eventcountry === "NZ" || eventcountry === "DE" || 
    eventcountry === "IT" || eventcountry === "FR" )

    {
      localStorage.setItem(
        "eventnameandcountry:",
        JSON.stringify({ eventName, eventcountry , eventimpact  , eventdate , eventtime , timeclickedurl })
      );   
      
      const url = new URL(window.location.origin + "/admin/eventshistory");
      url.searchParams.set("eventName", eventName);
      url.searchParams.set("eventcountry", eventcountry);
      url.searchParams.set("eventimpact", eventimpact);
      url.searchParams.set("eventtime", eventtime);
      url.searchParams.set("eventdate", eventdate);
      url.searchParams.set("timeclickedurl", timeclickedurl);
     
    
      // Open the new tab with the constructed URL
      const newTab = window.open(url.toString(), "_blank");
      // const newTab = window.open("/admin/eventshistory", "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        alert("Failed to open a new tab.");
      }

      // navigate("/eventshistory");
    } else {
      alert("Sorry, data is available  ");
    }
  };


  const matchedevents_with_oldtrades_foradmin = async ()=>{
    try{
      // const tokenfromls = localStorage.getItem("token");

      // const h = { authorization: tokenfromls };
      // const userid = localStorage.getItem("userregisterId")
      // console.log("userid" , userid)
      const response = await axios.get(`${process.env.REACT_APP_URL}/matchedevents_foradmin` )
      console.log("response_admin------>" , response.data.matchedEvents)
      setMatcheduserdata(response.data.matchedEvents)
    }
    catch(error){
      console.log("error" , error)
    }
    // const response = axios.get("")
  }

  useEffect(()=>{
    matchedevents_with_oldtrades_foradmin();
  },[])
  return (
    <div className="container mx-auto mt-[20vh]">
      <div className="overflow-x-auto">
       
          <div>
            {maindata.length === 0 ? (
              <div className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl `}>
                Sorry!! Data for this day is not available .
              </div>
            ) : (
              <table className={` ${styles.table} min-w-full border rounded overflow-x-scroll m-auto mt-[95%] sm:mt-[30%] md:mt-[14%] lg:mt-[8%] xl:mt-[1%] mb-[5%]`}>
                <thead key={2} className="bg-gray-800   text-white ">
                  <tr  key={1}>
                    <th  className=" sticky top-0 py-2 px-4">Date</th>
                    <th className=" sticky top-0 py-2 px-4">Time(GMT)</th>
                    <th className="sticky top-0 py-2 px-4">Country</th>
                    <th className="sticky top-0 py-2 px-4">Event</th>
                    <th className="sticky top-0 py-2 px-4">Currency</th>
                    <th className="sticky top-0 py-2 px-4">Previous</th>
                    <th className="sticky top-0 py-2 px-4">Estimate</th>
                    <th className="sticky top-0 py-2 px-4">Actual</th>
                    <th className="sticky top-0 py-2 px-4">Impact</th>
                    <th className="sticky top-0 py-2 px-4">Change</th>
                    <th className="sticky top-0 py-2 px-4">
                      Change Percentage
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {maindata.filter(item =>
                  //  selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true)
                  (selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true) &&
                  // Check if impact is selected or not, and apply the filter
                  (selectedImpacts.length > 0 ? selectedImpacts.includes(item.impact) : true))
                  .map((item) =>
                    !hideEstimate ||
                    (hideEstimate &&
                      item.estimate !== null &&
                      item.estimate !== "") ? (
                      <tr
                      key={item._id}
                        className="cursor-pointer hover:bg-gray-100 text-center"
                      >
                        <td className="py-2 px-4">
                          {item.date}
                        </td>
                        <td className="py-2 px-4">
                          {item.time}
                        </td>
                        <td  className="py-2 px-4">
                          {item.country}
                        </td>
                        <td
                          
                          className={`py-2 px-4 ml-[-5px] cursor-pointer ${
                            matcheduserdata.some((event) => event.event === item.event)
                              ? 'text-yellow-500' 
                              : 'text-blue-500' 
                          }`}
                          onClick={() => handleEventClick(item)}
                        >
                          {item.event}
                        </td>
                        <td  className="py-2 px-4">
                          {item.currency}
                        </td>
                        <td className="py-2 px-4">
                          {item.previous}
                        </td>
                        <td className="py-2 px-4">
                          {item.estimate}
                        </td>
                        <td className="py-2 px-4">
                          {item.actual}
                        </td>
                        <td className="py-2 px-4">
                          {item.impact}
                        </td>
                        <td className="py-2 px-4">
                          {item.change}
                        </td>
                        <td className="py-2 px-4">
                          {item.changePercentage}
                        </td>
                      </tr>
                    ) : <></>
                  )}
                </tbody>
              </table>
            )}
          </div>
       
      </div>

    
    </div>
  );
};

export default Table;
