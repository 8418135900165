import React, { useState, useEffect, useCallback } from "react";
import Table1 from "./Table1";
import Navbar from "./Navbar";
import axios from "axios";
import Papa from "papaparse";
import DownloadIcon from "../assets/downloada.png";
import refresh_icon from "../assets/refresh.png"
import matchicon from "../assets/match.png";
import { Bars } from "react-loader-spinner";
import Filtericon from "../assets/filter.png";
import { Select } from "antd";
import dashboardicon from "../assets/data-analysis.png";
import styles from "../../src/components/tradeall.module.css";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

const EconomicsCalender = () => {
  const [finalfiltereddata , setfinalfiltereddata] = useState("")
  const [clickedon, setclickedon] = useState("today");
  const [errorMessage, setErrorMessage] = useState("");
  const [maindata, setmaindata] = useState([]);
  const [clickedend, setclickedend] = useState("todaydata");
  const [datarecieved, setdatarecieved] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clickedButton, setClickedButton] = useState("Today");
  const [hideEstimate, setHideEstimate] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(50);
  const [selectedDates, setSelectedDates] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  // const handleDateChange = (dates) => {
  //   setSelectedDates(dates);
  //   setPage(1);  // Reset to first page on date change
  //   handlelast4monthsClick(dates, 1);  // Fetch data with the new date range and reset page
  // };

  const downloadCsv = () => {
    const csv = Papa.unparse(
      subscribers.map((subscriber) => ({
        date: subscriber.date,
        time: subscriber.time,
        country: subscriber.country,
        event: subscriber.event,
        currency: subscriber.currency,
        previous: subscriber.previous,
        estimate: subscriber.estimate,
        actual: subscriber.actual,
        change: subscriber.change,
        impact: subscriber.impact,
        changePercentage: subscriber.changePercentage,
      })),
      {
        headers: [
          "date",
          "time",
          "country",
          "event",
          "currency",
          "previous",
          "estimate",
          "actual",
          "change",
          "impact",
          "changePercentage",
        ],
      }
    );
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "eventdata.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // <----------for downloading excel from backend---------->
  const fetchallData = async () => {
    try {
      const tokenfromls = localStorage.getItem("token");
      const h = { authorization: tokenfromls };
      const response = await axios.get(`${process.env.REACT_APP_URL}/getdata`, {
        headers: h,
      });
      setSubscribers(response.data);
      setdatarecieved(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // <<-------for last4months ,  today , yesterday , tomorrow , and next week data on screen -------->>

  const [dateRange, setDateRange] = useState(null); 
  const handleDateChange = async(dates, dateStrings) => {
    setLoading(true);
    // const tokenfromls = localStorage.getItem("token");
    // const h = { authorization: tokenfromls };
    const [startDate, endDate] = dateStrings;
  
    try{
      const response = await axios.post(`${process.env.REACT_APP_URL}/datepicker` ,  { startDate, endDate } , 
          // { headers: h }
        );
     
      // console.log("Dates---->" , startDate , "" , + "" , endDate)
      setmaindata(response.data);
      setClickedButton("");
        setclickedon("");
       setclickedend("");
      setLoading(false);
      
      // console.log("response.data" , response.data)
    }
      catch(error) {
        setClickedButton("");
        setclickedon("");
       setclickedend("");
        setLoading(false);
        const errorMessage = error.response && error.response.status === 400 ? error.response.data.error : "Error fetching data. Please try again later.";
        setErrorMessage(errorMessage);
        console.error("Error fetching data:", error);
      };
      setDateRange(dates);
  };

  const handlelast4monthsClick = async() => {
    setLoading(true);
    // console.log("hlo am clicked");

    const tokenfromls = localStorage.getItem("token");
    const h = { authorization: tokenfromls };

    const endpoint = `${process.env.REACT_APP_URL}/last4monthsdata`;
  try{
  const response = await axios.get(endpoint, { headers: h })
    
  let filteredData = response.data;
  // console.log("filteredData" , filteredData)
  setmaindata(filteredData.uniqueEvents);
  setLoading(false);
  // setfinalfiltereddata(filteredData)
  setClickedButton("Last4months");
  setclickedon("last4months");
  setclickedend("last4monthsdata");

  setDateRange(null);
  // setmaindata(filteredData);
  }
  catch(error) {
        setClickedButton("Last4months");
        setclickedon("last4months");
       setclickedend("last4monthsdata");
        setLoading(false);
        setDateRange(null);
        const errorMessage = error.response && error.response.status === 400 
          ? error.response.data.error 
          : "Error fetching data. Please try again later.";
        setErrorMessage(errorMessage);
        console.error("Error fetching data:", error);
      };
  };

  const handleTodayClick = async() => {
    // console.log("hi today api git hit")
    setLoading(true);

    const tokenfromls = localStorage.getItem("token");

    const h = { authorization: tokenfromls };

    const endpoint = `${process.env.REACT_APP_URL}/todaydata`;
 try{
  // console.log("hi today try")
 
  const response =  await axios.get(endpoint, { headers: h })
  let filteredData = response.data;
  // console.log("filteredData" , filteredData)
  setClickedButton("Today");
//  return;
  setmaindata(filteredData.uniqueEvents);
  setLoading(false);
  setfinalfiltereddata(filteredData.uniqueEvents)
  setclickedon("today");
  setclickedend("todaydata");

 
  setDateRange(null);
 }
 catch(error){
  setLoading(false);
  setClickedButton("Today");

  setclickedon("today");
  setclickedend("todaydata");
  setDateRange(null);
  if (error.response && error.response.status === 400) {
    setErrorMessage(errorMessage);
    setmaindata([]);
  } else {
    const errorMessage = "Error fetching data. Please try again later.";
    setErrorMessage(errorMessage);
  }
  console.error("Error fetching data:", error);
 }

  };
  const handleYesterdayClick = async() => {
    setLoading(true);
    const tokenfromls = localStorage.getItem("token");

    const h = { authorization: tokenfromls };
    const endpoint = `${process.env.REACT_APP_URL}/yesterdaydata`;
    try{
    const response1 = await axios.get(endpoint, { headers: h })
 
    let filteredData = response1.data;
    setClickedButton("Yesterday");
    setmaindata(filteredData.uniqueEvents);
    setLoading(false);
    setfinalfiltereddata(filteredData.uniqueEvents)
    setclickedon("yesterday");
    setclickedend("yesterdaydata");
   
    setDateRange(null);
  
}
       catch(error) {
        setLoading(false);
        setClickedButton("Yesterday");

        setclickedon("yesterday");
        setclickedend("yesterdaydata");
        setDateRange(null);
        if (error.response && error.response.status === 400) {
          setErrorMessage(errorMessage);
          setmaindata([]);
        } else {
          const errorMessage = "Error fetching data. Please try again later.";
          setErrorMessage(errorMessage);
        }
        console.error("Error fetching data:", error);
      };
  };
  const handleTomorrowClick = async() => {
    // console.log("hi am tomo clicked api")
    setLoading(true);
    setErrorMessage("");

    const tokenfromls = localStorage.getItem("token");

    const h = { authorization: tokenfromls };
    const endpoint = `${process.env.REACT_APP_URL}/tomorrowdata`;
    try{
      // console.log("hi am in try tomorrow")

    const response2 = await axios.get(endpoint, { headers: h })

    const filteredData = response2.data;
    // console.log("tom result---------------", filteredData)
    // return;
    setClickedButton("Tomorrow");
    setmaindata(filteredData.uniqueEvents);
    setLoading(false);
    setfinalfiltereddata(filteredData.uniqueEvents)
    setclickedon("tomorrow");
    setclickedend("tomorrowdata");
  
    setDateRange(null);
  }
    catch(error) {
        setLoading(false);
        setClickedButton("Tomorrow");
        setclickedon("tomorrow");
        setclickedend("tomorrowdata");
        setDateRange(null);
        if (error.response && error.response.status === 400) {
          setErrorMessage(errorMessage);
          setmaindata([]);
        } else {
          const errorMessage = "Error fetching data. Please try again later.";
          setErrorMessage(errorMessage);
        }
        console.error("Error fetching data:", error);
      };
  };
  const handleweekClick = async() => {
    setLoading(true);
    const tokenfromls = localStorage.getItem("token");

    const h = { authorization: tokenfromls };
    setclickedend("nextdaysdata");
    const endpoint = `${process.env.REACT_APP_URL}/nextdaysdata`;
    try{
    const response3 = await axios.get(endpoint, { headers: h })
  // console.log("response3" , response3)
    // const flattenedData = Array.isArray(response3.data)
    //   ? response3.data.uniqueEvents.flatMap((innerArray) => innerArray)
    //   : [];
const flattenedData = response3.data.uniqueEvents
      // console.log("flattenedData" , flattenedData)
    setClickedButton("Next7Days");
    // setmaindata(flattenedData);
    setmaindata(flattenedData);
    setfinalfiltereddata(flattenedData)
    setclickedon("nextdays");
    setclickedend("nextdaysdata");
    setLoading(false);
    setDateRange(null);
  }
    catch(error) {
        setLoading(false);
        setClickedButton("Next7Days");
        setclickedon("nextdays");
        setclickedend("nextdaysdata");
        setDateRange(null);
        if (error.response && error.response.status === 400) {
          setErrorMessage(errorMessage);
          setmaindata([]);
        } else {
          const errorMessage = "Error fetching data. Please try again later.";
          setErrorMessage(errorMessage);
        }
        console.error("Error fetching data:", error);
      };
  };

  useEffect(() => {
    // console.log("hi am in useefect")
    handleTodayClick();
    // fetchallData();
  }, []);





  // <----------impact and country filters---------->
  const { Option } = Select;
  const [selectedImpacts, setSelectedImpacts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  
  const handleCountryChange = (values) => {
    // console.log("hlo am in country filter")
    // setLoading(true);
    setSelectedCountry(values);
    // console.log("values.length" )
   
  
  };



  useEffect(()=>{
 
    handleTodayClick()
  }, [])

  
  const handleImpactChange = (values) => {

    setSelectedImpacts(values)
  }
  const [isReloadLoading, setIsReloadLoading] = useState(false);

  const handleRefresh = () => {
    setIsReloadLoading(true);
    setLoading(false);
    // Loop through localStorage and remove any item with "Clickeddate_data_"
    Object.keys(localStorage).forEach((key) => {
      if (
          key.includes("Clickeddate_data_") || 
          key.includes("table3_historyData_") || 
          key.includes("clickedmodal_data_2ndpage_")
      ) {
          localStorage.removeItem(key);
      }
  });
  
    localStorage.removeItem("All_events_history");
    localStorage.removeItem("lastDeletionTime");
    localStorage.removeItem("UserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedtime");
    localStorage.removeItem("4GraphDataobjects");
    localStorage.removeItem("userregisterId");
    localStorage.removeItem("username");
    localStorage.removeItem("usertoken");
    localStorage.removeItem("todayDate");
    localStorage.removeItem("previousDate_u_search_history");
    localStorage.removeItem("eventnameandcountry:");

    localStorage.removeItem("selectedtime");
    // localStorage.removeItem("previousDate_u_search_history");
    // localStorage.removeItem("previousDate_u_search_history");

   
    // window.location.reload();
  };


  const [modalShow, setModalshow] = useState(false);

  const handleModalShowClick = () => {
    setModalshow(true);
  };

  
  // useEffect(()=>{
  //   handleCountryChange(selectedCountry)
 
  // }, [selectedCountry])


  // const handleImpactChange = (values) => {
  //   console.log("hlo am in impact filter")
  //   setLoading(true);
  //   setSelectedImpacts(values);
  //   setLoading(false);
  //   setPage(1); // Reset page number
  //   if (selectedDates.length === 2) {
  //     const [startDate, endDate] = selectedDates;
  //     fetchpickerdatesData(startDate, endDate, 1); // Re-fetch data with updated filters
  //   }
  //   setLoading(false);
  //   const filteredData = maindata.filter((item) =>
  //     values.includes(item.impact)
  //   );
  //   setmaindata(filteredData);

    // const fetchData = async () => {
    //   console.log("hlo am in fetchdata function ...>");
    //   try {
    //     const tokenfromls = localStorage.getItem("token");

    //     const h = { authorization: tokenfromls };
    //     const response = await axios.get(
    //       `${process.env.REACT_APP_URL}/${clickedend}`,
    //       { headers: h }
    //     );
    //     let filteredData = response.data;

    //     // <------------------code for next 7 days ---------->
    //     if (clickedend === "nextdaysdata") {
    //       setLoading(true);
    //     
    //       if (
    //         values &&
    //         values.length > 0 &&
    //         values[0] !== "All Impact" &&
    //         selectedCountry &&
    //         selectedCountry.length > 0 &&
    //         selectedCountry[0] !== "All Countries"
    //       ) {
    //        
    //         setLoading(true);
    //         // const flattenedData = filteredData.flatMap((innerArray) =>
    //         //   innerArray.flat()
    //         // );

    //         const flattenedData = Array.isArray(response.data)
    //           ? response.data.flatMap((innerArray) => innerArray)
    //           : [];
    //         // let filteredData = filterData(flattenedData);
    //         filteredData = flattenedData.filter(
    //           (item) =>
    //             values.includes(item.impact) &&
    //             selectedCountry.includes(item.country)
    //         );
    //         setLoading(false);
    //       } else if (
    //         values &&
    //         values.length > 0 &&
    //         values[0] !== "All Impact"
    //       ) {
    //         setLoading(true);
    //         const flattenedData = Array.isArray(response.data)
    //           ? response.data.flatMap((innerArray) => innerArray)
    //           : [];
    //         filteredData = flattenedData.filter((item) =>
    //           values.includes(item.impact)
    //         );
    //         setLoading(false);
    //       } else if (
    //         selectedCountry &&
    //         selectedCountry.length > 0 &&
    //         selectedCountry[0] !== "All Countries"
    //       ) {
    //         setLoading(true);
    //         // const flattenedData = filteredData.flatMap((innerArray) =>
    //         //   innerArray.flat()
    //         // );

    //         const flattenedData = Array.isArray(response.data)
    //           ? response.data.flatMap((innerArray) => innerArray)
    //           : [];
    //         filteredData = flattenedData.filter((item) =>
    //           selectedCountry.includes(item.country)
    //         );
    //         setLoading(false);
    //       }
    //     }

    //     // <------------------code for today , yesterday , tomorrow ---------->
    //     else {
    //       setLoading(true);
    //       if (
    //         values &&
    //         values.length > 0 &&
    //         values[0] !== "All Impact" &&
    //         selectedCountry &&
    //         selectedCountry.length > 0 &&
    //         selectedCountry[0] !== "All Countries"
    //       ) {
    //         setLoading(true);
    //         filteredData = filteredData.filter(
    //           (item) =>
    //             values.includes(item.impact) &&
    //             selectedCountry.includes(item.country)
    //         );
    //         setLoading(false);
    //       } else if (
    //         values &&
    //         values.length > 0 &&
    //         values[0] !== "All Impact"
    //       ) {
    //         setLoading(true);
    //         filteredData = filteredData.filter((item) =>
    //           values.includes(item.impact)
    //         );
    //         setLoading(false);
    //       } else if (
    //         selectedCountry &&
    //         selectedCountry.length > 0 &&
    //         selectedCountry[0] !== "All Countries"
    //       ) {
    //         // setLoading(true)
    //         // filteredData = response.data.filter((item) =>
    //         //   selectedCountry.includes(item.country))

    //         setLoading(true);
    //         filteredData = response.data.filter((item) =>
    //           selectedCountry.includes(item.country)
    //         );
    //         // setLoading(false)
    //       }
    //     }

    //     setmaindata(filteredData);
    //     setLoading(false);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };

    // fetchData();
  // };

  const handlematchedaashi = () => {
    window.open("/matchedevents", "_blank");
  };

 
  // const fetchData = async () => {
  //   setLoading(true);
  //   const tokenfromls = localStorage.getItem("token");
  //   const h = { authorization: tokenfromls };

  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_URL}/${clickedend}`,
  //       { headers: h }
  //     );
  //     let filteredData = response.data;

  //     if (clickedend === "nextdaysdata") {
  //       setLoading(true);
  //      
  //       filteredData = Array.isArray(response.data)
  //         ? response.data.flatMap((innerArray) => innerArray)
  //         : [];
  //       setLoading(false);
  //     }

  //     if (
  //       selectedCountry.length > 0 &&
  //       selectedCountry[0] !== "All Countries"
  //     ) {
  //       setLoading(true);
  //       filteredData = filteredData.filter((item) =>
  //         selectedCountry.includes(item.country)
  //       );
  //       setLoading(false);
  //     }

  //     if (selectedImpacts.length > 0 && selectedImpacts[0] !== "All Impact") {
  //       setLoading(true);
  //       filteredData = filteredData.filter((item) =>
  //         selectedImpacts.includes(item.impact)
  //       );
  //       setLoading(false);
  //     }

  //     setmaindata(filteredData);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [selectedCountry, selectedImpacts, clickedend]);

  // useEffect(() => {
  //   if (selectedDates.length === 2) {
  //     const [startDate, endDate] = selectedDates;
  //     fetchpickerdatesData(startDate, endDate, page);
  //   }
  // }, [selectedCountry, selectedImpacts, selectedDates, page]);


  // <--------------toggle estimate fields on frontend------------>
  const handleEstimateToggle = () => {
    setHideEstimate(!hideEstimate);
  };

  const handleadminpannel = () => {
    window.open("/admin/dashboard", "_blank");
  };
  
  return (
    <div>
      <div>
        <div>
          <Navbar />
        </div>

        <div className="flex flex-row">
          <div className="w-[100%] ">
            <div className="mt-[80px] w-[100%]">
              <div className="">
                <div className="flex z-[999] flex-row w-[100%] bg-slate-400 justify-between p-3 fixed ">
                  <div>
                    <button className={` text-white hover:text-white `}>
                      <div className="flex items-center w-50 justify-center">
                        <img
                          src={Filtericon}
                          alt="Filter Icon"
                          className="h-6 w-6 mr-2"
                        />
                        <span className="text-lg font-bold">Filter</span>
                      </div>
                    </button>
                  </div>

                  <div className="mt-[8%] sm:mt-[9%] md:mt-0 lg:mt-0 xl:mt-0 buttons flex flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row md:space-x-4 items-center  sm:items-center md:items-start lg:items-start xl:items-center justify-center">
                    <div className="first-div flex flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row">
                      <div className=" flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row ml-[-6%] sm:ml-0 md:ml-[16px] lg:ml-[16px] xl:ml-0 flex">
                        <div className="relative block sm:block md:inline-block lg:inline-block xl:inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3  mb-4 sm:mb-4 md:mb-0 lg:mb-0 xl:mb-0 ">
                          <Space style={{ width: "100%" }} direction="vertical">
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "200px" }}
                              placeholder={
                                <span style={{ color: "#666666" }}>
                                  Country
                                </span>
                              }
                              onChange={handleCountryChange}

                              // <-----stop movement above bottom---->
                              getPopupContainer={(trigger) => trigger.parentNode}
                              className=""
                            >
                              <Option value="UK">UK</Option>
                              <Option value="US">US</Option>
                              <Option value="CA">CA</Option>
                              <Option value="CH">CH</Option>
                              <Option value="EU">EU</Option>
                              <Option value="JP">JP</Option>
                              <Option value="AU">AU</Option>
                              <Option value="NZ">NZ</Option>
                              <Option value="DE">DE</Option>
                              <Option value="IT">IT</Option>
                              <Option value="FR">FR</Option>
                            </Select>
                          </Space>
                        </div>

                        <div className="relative inline-block px-0 sm:px-3 md:px-3 lg:px-3 xl:px-3">
                          <Space style={{ width: "100%" }} direction="vertical">
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "200px" }}
                              placeholder={
                                <span style={{ color: "#666666" }}>Impact</span>
                              }
                              onChange={handleImpactChange}
                              getPopupContainer={(trigger) => trigger.parentNode}
                              popupClassName="text-black"
                              className="text-black placeholder-black"
                            >
                              <Option value="High">High</Option>
                              <Option value="Medium">Medium</Option>
                              <Option value="Low">Low</Option>
                            </Select>
                          </Space>
                        </div>
                      </div>

                      <div className={`range-picker mt-4 sm:mt-4 md:mt-4 lg:mt-0 xl:mt-0 ml-[-6%] sm:ml-[10%] md:ml-[10%] lg:ml-[6%] xl:ml-0  ${clickedButton === "datepicker"}`} >
                        <Space direction="vertical" size={12}>
                          {/* <RangePicker onChange={handleDateChange}/> */}
                          <RangePicker 
                          value={dateRange} 
                          onChange={(dates, dateStrings) => handleDateChange(dates, dateStrings)} 
                        />
                        </Space>
                      </div>

                    </div>

                    {/* <---hlo chnges start-----> */}

                    <div className="second-div flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row items-center justify-center mt-5 sm:mt-5 md:mt-5 lg:mt-5 xl:mt-0 ml-[-6%] sm:ml-[-6%] md:ml-[-6%] lg:ml-0 xl:ml-0">
                      <div
                        className={`${styles.hidebtn} flex ml-[-13%] sm:ml-[-13%] md:0 lg:ml-0 xl:ml-0`}
                      >
                        <div
                          className={`flex ${styles.btncss} flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row`}
                        >
                          <div
                            className={`${styles.btncss1} relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-1 mb-2 sm:mb-2 md:mb-0 lg:mb-0 xl:mb-0`}
                          >
                            <button
                              type="button"
                              onClick={handleEstimateToggle}
                              className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[50px] xl:h-[50px] inline-flex items-center bg-gray-700 hover:bg-gray-800 justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 `}
                            >
                              {hideEstimate
                                ? "Show All Estimate"
                                : "Hide Empty Estimate"}
                            </button>
                          </div>

                          <div
                            className={`${styles.btncss1} relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-1  mb-2 sm:mb-2 md:mb-0 lg:mb-0 xl:mb-0`}
                          >
                            <button
                              type="button"
                              onClick={handlelast4monthsClick}
                              className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[50px] xl:h-[50px] inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                                clickedButton === "Last4months"
                                  ? "bg-slate-500"
                                  : "bg-gray-700 hover:bg-gray-800"
                              }`}
                            >
                              Last 4 months
                            </button>
                          </div>

                          <div
                            className={`${styles.btncss1}relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-1`}
                          >
                            <button
                              type="button"
                              onClick={handleYesterdayClick}
                              className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[50px] xl:h-[50px] inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                                clickedButton === "Yesterday"
                                  ? "bg-slate-500"
                                  : "bg-gray-700 hover:bg-gray-800"
                              }`}
                            >
                              Yesterday
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className=" flex mt-5 sm:mt-5 md:mt-0 lg:mt-0 xl:mt-0 ml-0 sm:ml-[-5%] md:ml-0 lg:ml-0 xl:ml-0">
                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-1">
                          <button
                            type="button"
                            onClick={handleTodayClick}
                            className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[50px] xl:h-[50px] inline-flex items-center justify-center w-full px-2 sm:px-2 md:px-4 lg:px-4 xl:px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                              clickedButton === "Today"
                                ? "bg-slate-500"
                                : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                            Today
                          </button>
                        </div>
                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-1">
                          <button
                            type="button"
                            onClick={handleTomorrowClick}
                            className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[50px] xl:h-[50px] inline-flex items-center justify-center w-full px-2 sm:px-2 md:px-4 lg:px-4 xl:px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                              clickedButton === "Tomorrow"
                                ? "bg-slate-500"
                                : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                            Tomorrow
                          </button>

                          {/* Render your main data here */}
                        </div>
                        <div className="relative inline-block px-1 sm:px-1 md:px-1 lg:px-1 xl:px-1">
                          <button
                            type="button"
                            onClick={handleweekClick}
                            className={`h-[61px] sm:h-[61px] md:h-[65px] lg:h-[50px] xl:h-[50px] inline-flex items-center justify-center w-full px-2 sm:px-2 md:px-4 lg:px-4 xl:px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ${
                              clickedButton === "Next7Days"
                                ? "bg-slate-500"
                                : "bg-gray-700 hover:bg-gray-800"
                            }`}
                          >
                            Next 7 Days
                          </button>
                          {loading && (
                            <div className="flex items-center justify-center h-screen w-screen loader-overlay ">
                              <Bars
                                height="80"
                                width="80"
                                color="#94A3B8"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                className="loader"
                              />
                            </div>
                          )}
                        </div>

                        {/* <div className="flex  space-x-4 px-0 sm:px-0 md:px-1 lg:px-1 xl:px-1 mt-[-1%]">
                          <div
                            className={` cursor-pointer transition transform hover:scale-110 disabled:opacity-50 ${
                              !datarecieved
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                            onClick={datarecieved ? downloadCsv : () => {}}
                            disabled={!datarecieved}
                          >
                            <img
                              src={DownloadIcon}
                              title="Download all Data"
                              alt="Download"
                              width={45}
                              height={55}
                            />
                          </div>
                        </div> */}
                        <div>
                           <img
                            src={dashboardicon}
                            alt="dashboardicon"
                            title="Dashboard"
                            className="h-8 w-8 mr-2 ml-2 mt-1 cursor-pointer"
                            onClick={handleadminpannel}
                          />
                        </div>

                        {/* <div>
                         
                        
                         
                         />
                       </div> */}

                 <div onClick={handleModalShowClick} >
                    {/* <TfiReload className="cursor-pointer h-[25px] w-[20px] top-[10px]  "/> */}

                    <img
                           src={refresh_icon}
                           alt="refresh button"
                           title="Refresh"
                           className="h-8 w-8 mr-2 mt-1 cursor-pointer "
                         />
                   
                  </div>
                        {/* <div className="flex items-center space-x-4 px-0 ">
                          <div
                            className="cursor-pointer transition transform hover:scale-110 disabled:opacity-50"
                            onClick={handlematchedaashi}
                          >
                            <img
                              src={matchicon}
                              title="Match with ukinvesting.com"
                              alt="match"
                              width={37}
                              height={40}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[100px]">
              <Table1
                optionvalue={clickedon}
                maindata={maindata}
                hideEstimate={hideEstimate}
                selectedCountry = {selectedCountry}
                selectedImpacts = {selectedImpacts}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        {modalShow && (
          <div
            id="popup-modal"
            className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center bg-black bg-opacity-15 z-50"
            onClick={() => setModalshow(!modalShow)}
          >
            <div className="relative p-4 w-full max-w-md">
              <div className="relative bg-white rounded-lg shadow">
                <button
                  type="button"
                  className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                  data-modal-hide="popup-modal"
                  onClick={() => setModalshow(!modalShow)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      onClick={() => setModalshow(!modalShow)}
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-4 md:p-5 text-center">
                  {/* <div className="flex justify-center items-center mt-5">
                                   <CiCircleInfo size={40} color='grayZ'/>
                                </div> */}
                  <h3 className="mb-5 mt-5 text-lg font-normal text-black">
                    Are you sure , you want to clear all data from Local
                    Storage? 
                  </h3>
                  <div className="flex items-center justify-center">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="text-white font-bold bg-gray-700 mt-3 font-medium rounded-lg text-sm inline-flex items-center justify-center px-3 py-2 w-[20%]"
                      onClick={handleRefresh}
                    >
                      Yes
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="ml-4 text-white font-bold bg-gray-700 mt-3 font-medium rounded-lg text-sm inline-flex items-center justify-center px-3 py-2 w-[20%]"
                      onClick={() => setModalshow(!modalShow)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EconomicsCalender;
