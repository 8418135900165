import React, { useEffect, useState } from 'react';
import styles from "../../components/tradeall.module.css";
import axios from 'axios';
import RingLoader from "react-spinners/RingLoader";
const UsersTradedata = () => {
  const [allUserstrades, setAllUserstrades] = useState([]);
  const [Loading, setLoading] = useState(true);
  const getAllUsers = async () => {
    try {
      // const tokenfromls = localStorage.getItem("token");
      // const h = { authorization: tokenfromls };
      const response = await axios.get(`${process.env.REACT_APP_URL}/getuserssavedtradingdata`);
      setLoading(false)
      const data1 = response.data.latestDocuments;
      setAllUserstrades(data1); 
    } catch (error) {
      console.log("error", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>

<div className='flex justify-center items-center text-[18px]'><h1 className='font-bold m-5'>Selected trading data by different Users :-</h1></div>
    {
       Loading ?  <div className="flex items-center justify-center  mt-10 w-full">
       <RingLoader 
         height="80"
         width="80"
         color="#94A3B8"
         ariaLabel="bars-loading"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         className=" flex items-center justify-center  mt-10 w-[100%]"
       />
     </div> :   <div className="container mx-auto mt-[10vh]">
        <div className="overflow-x-auto">
          {allUserstrades.map((user , index) => (
            <>
          <div className='flex'>  <h1 className='font-bold'>{index+1}.) </h1>
          <h1 className="font-bold pl-3">UserName : {user.userDetails.name}</h1></div>
          
              <h1 className="font-bold">User Email Id :  {user.userDetails.email}</h1>
              <table className={`${styles.table} min-w-full border rounded overflow-x-scroll m-auto mt-4 mb-10`}>
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="sticky top-0 py-2 px-4">S.No.</th>
                    <th className="sticky top-0 py-2 px-4 min-w-[145px]">Selection Date</th>
                    <th className="sticky top-0 py-2 px-4">Date</th>
                    <th className="sticky top-0 py-2 px-4">Time</th>
                    <th className="sticky top-0 py-2 px-4">Country</th>
                    <th className="sticky top-0 py-2 px-4 min-w-[350px]">Event</th>
                    <th className="sticky top-0 py-2 px-4">Currency</th>

                    <th className="sticky top-0 py-2 px-4">Trade</th>
                    <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                    <th className="sticky top-0 py-2 px-4">Investing.com Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading view Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading Economics Var name</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Comments</th>
                    <th className="sticky top-0 py-2 px-4">Trade log(profit/loss)</th>
                    <th className="sticky top-0 py-2 px-4">Trade in time(seconds)</th>
                    <th className="sticky top-0 py-2 px-4">Trade comment</th>
                  </tr>
                </thead>
                <tbody>
                  {user.dates.map((dateObj, index) => (
                    <tr key={index} className=" hover:bg-gray-100 text-center">
                      <td className="py-2 px-4">{index + 1}.</td>
                      <td className="py-2 px-4">{dateObj.date}</td>
                      <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.date}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.time}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.country}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.event}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.currency}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.trade}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.trade_grade}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.investing_name}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.trading_view}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.trading_economics}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.movement_currency}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.movement_correlation}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.flat_currency}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.flat_correlation}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.comments}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.trade_log}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.trade_time}</div>
              </div>
            ))
          )}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.length === 0 ? (
            <div>No trades</div>
          ) : (
            dateObj.latestDocument.trades.map(trade => (
              <div key={trade._id}>
                <div>{trade.trade_comment}</div>
              </div>
            ))
          )}
        </td>

                    
                    </tr>
                  ))}
                </tbody>
              </table>
              </>
          ))}
        </div>
      </div>
    }
   

    
    </>
  );
};

export default UsersTradedata;





{/* <tbody>
  {user.dates
    .filter(dateObj => dateObj.latestDocument.trades.length > 0) 
    .map((dateObj, index) => (
      <tr key={index} className="hover:bg-gray-100 text-center">
        <td className="py-2 px-4">{index + 1}.</td>
        <td className="py-2 px-4">{dateObj.date}</td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.date}</div>
            </div>
          ))}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.time}</div>
            </div>
          ))}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.country}</div>
            </div>
          ))}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.event}</div>
            </div>
          ))}
        </td>
        <td className="py-2 px-4">
          {dateObj.latestDocument.trades.map(trade => (
            <div key={trade._id}>
              <div>{trade.currency}</div>
            </div>
          ))}
        </td>
      </tr>
    ))}
</tbody> */}
