import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavLink from "../Navlink";
import logo from "../../assets/altrafxlogo.png";
import { FaRegHandshake } from "react-icons/fa";
import { MdOutlineLogout } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
// import brokerageicon from "../../assets/brokerage.png"
import usericon from "../../assets/usericon1.png"
const UserNavbar = () => {
const navigate = useNavigate();
const [brokermodal , setbroker_modal] = useState(false);
const [broker , setbroker]= useState("");
const [brokerid , setbrokerid]= useState("");
const [brokerpassword , setBrokerpassword]= useState("");
const [username , setusername]= useState("")
const [showPassword, setShowPassword] = useState(false);


  const handleLogout = () => {
    Object.keys(localStorage).forEach((key) => {
      if (
          key.includes("Clickeddate_data_") || 
          key.includes("table3_historyData_") || 
          key.includes("clickedmodal_data_2ndpage_")
      ) {
          localStorage.removeItem(key);
      }
  });
    localStorage.removeItem("selected_trade_events");
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userregisterId");
    localStorage.removeItem("username");
    localStorage.removeItem("4GraphDataobjects");
    localStorage.removeItem("username");
    
    localStorage.removeItem("lastDeletionTime");
    

    localStorage.removeItem("token");
    localStorage.removeItem("adminregisterId");
    localStorage.removeItem("lastDeletionTime");
    localStorage.removeItem("All_events_history");
    localStorage.removeItem("UserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("selectedtime");
    localStorage.removeItem("eventnameandcountry:");
    localStorage.removeItem("userregisterId");
    localStorage.removeItem("username");
    localStorage.removeItem("usertoken");
    localStorage.removeItem("todayDate");
    localStorage.removeItem("previousDate_u_search_history");
    localStorage.removeItem("adminregisterId");
 
    localStorage.removeItem("todayDate");
    navigate("/");
  };

const handlebrokermodal =()=>{
  setbroker_modal(true);
}

const closebrokermodal =()=>{
  setbroker_modal(false);
 
}

const submitbroker_data = async (e) => {
  e.preventDefault();
 
  const tokenfromls = localStorage.getItem("usertoken");
  const h = { authorization: tokenfromls };
 
  const userId = localStorage.getItem('userregisterId');
  if (!userId) {
      toast.error("User ID not found. Please login again.", { position: "top-right" });
      return;
  }


  
  if (!broker || !brokerid || !brokerpassword) {
    toast.error("Please fill all inputs first", {
      position: "top-right",
    });
    return;
  }

  
  try {
    const tokenfromls = localStorage.getItem("usertoken");
        const h = { authorization: tokenfromls };
      const response = await axios.post(
          `${process.env.REACT_APP_URL}/user/userbrokerdata/${userId}`,
          
      {
         broker , brokerid ,brokerpassword
      },
      {
        headers: {
          "Content-Type": "application/json", ...h
        },
        validateStatus: (status) => {
          return status >= 200 && status < 500;  // Accept all status codes between 200 and 499
        },
      } 
    );

    setBrokerpassword("");
      setbroker("");
      setbrokerid("");
      closebrokermodal()
      toast.success("Registration successful!", { position: "top-right" });
      // console.log("User registration successful ", response);
      window.location.reload()
      
  } 
  catch (error) {
    console.log("hlo am in catch")
    console.error("Error during sending data:", error);
  
    toast.error("Error in saving data, please fill all fields", {
      position: "top-right",
    });
  }
};



useEffect(()=>{
const loginusername = localStorage.getItem("username")
setusername(loginusername);
},[])



  return (
    <nav className="bg-gray-900 p-4 fixed top-0 w-full z-50">
      <div className=" flex justify-between items-center">
        <div className="flex items-center">
        <NavLink to="/user/economicdata">
          <img src={logo} alt="Logo" className="h-12 w-26" />
          </NavLink>
       
        </div>

        <div className="flex items-center justify-center">
         <div className="flex mr-10 sm:mr-10 md:mr-0 lg:mr-0 xl:mr-0"><img
                          src={usericon}
                          alt="user_icon"
                          className="h-5 w-5 mr-2 mt-1 "
                          
                         
                        /><h1 className="hidden sm:hidden md:block lg:block xl:block text-white mr-10">  {username}</h1></div>
          <div className="flex flex-row " onClick={handlebrokermodal}>
            <FaRegHandshake className="text-white relative top-1 right-2 cursor-pointer"  title="Fill Broker info"/>
            {/* <img
                          src={brokerageicon}
                          alt="brokerage_icon"
                          className="h-5 w-5 mr-2 mt-2 cursor-pointer"
                          title="Fill Broker info"
                         
                        /> */}
            <button  className="text-white hidden sm:hidden md:block lg:block xl:block" title="Broker info">
              Broker info
            </button>
          </div>
          <div className="flex flex-row ml-10 " onClick={handleLogout}>
            <MdOutlineLogout className="text-white relative top-1 right-1 cursor-pointer" title="Logout"/>
            <button  className="text-white hidden sm:hidden md:block lg:block xl:block" >
              Logout
            </button>
          </div>



          {brokermodal && <>
          




<div  className="mt-[40%] sm:mt-[40%] md:mt-[15%] lg:mt-[10%] xl:mt-[5%]  backdrop-blur-sm overflow-y-auto overflow-x-hidden fixed top-50 flex  z-[999] justify-center items-center w-full inset-0 h-full max-h-full">
    <div className="relative p-4 w-full max-w-md max-h-full">
      
        <div className="relative bg-white rounded-lg shadow-lg shadow-blue-500/50 ">
           
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                <h3 className="text-xl font-bold  my-2">
                    Fill your Broker information here:
                </h3>
                <button type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal" onClick={closebrokermodal}>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
           
            <div className="p-4 md:p-5">
                <form className="space-y-6 my-3" action="#">
                    <div>
                        <label for="broker" className="block mb-2 text-sm font-bold ">Broker</label>
                        <input type="text" name="broker" id="broker" className="focus:border-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="broker" required  onChange={(e) => setbroker(e.target.value)}/>
                    </div>
                    <div>
                        <label for="brokerid" className="block mb-2 text-sm font-bold ">Broker Id</label>
                        <input type="text" name="brokerid" id="brokerid" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="brokerid" required onChange={(e) => setbrokerid(e.target.value)}/>
                    </div>
                    <div>
                        {/* <label for="brokerpassword" className="block mb-2 text-sm font-bold ">Broker password</label>
                        <input type="password" name="brokerpassword" id="brokerpassword" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  " required onChange={(e) => setBrokerpassword(e.target.value)}/> */}
                    
                    
                    <label for="brokerpassword" className="block mb-2 text-sm font-bold ">Broker password</label>
                    <input
                      id="brokerpassword"
                      name="brokerpassword"
                      type={showPassword ? "text" : "password"}
                      value={brokerpassword}
                      onChange={(e) => setBrokerpassword(e.target.value)}
                      autoComplete="off"
                      required
                      className="w-full py-3 input-field border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow pr-10"
                      placeholder="••••••••"
                    />
                    {showPassword ? (
                      <IoIosEye
                        className="absolute top-[75%] transform -translate-y-1/2 right-6 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      // <img src={eye_img_open}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                      <IoIosEyeOff
                        className="absolute top-[75%] transform -translate-y-1/2 right-6 cursor-pointer text-gray-500"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      // <img src={eye_img}  onClick={() => setShowPassword(!showPassword)} className="absolute top-[70%] transform -translate-y-1/2 right-3 cursor-pointer text-gray-500 w-[16px]"></img>
                    )}
                
                    
                    </div>
                    
                    <button type="submit" className=" w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center " onClick={submitbroker_data}>Submit</button>
                   
                </form>
            </div>
        </div>
    </div>
</div> 

          </> }
        </div>
      </div>
    </nav>
  );
};

export default UserNavbar;
