import React, { useState } from "react";
import { toast } from "react-toastify";
// import { NavLink } from "react-router-dom";
import {LineWave} from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/altrafxlogo_black.png";
// import bannerimg from "../../assets/userbanner.png";
import { useNavigate } from "react-router-dom";
// import logo from "../assets/logo.png";
// import { IoIosEye } from "react-icons/io";
// import { IoIosEyeOff } from "react-icons/io";
import axios from "axios";
// import { PiArrowSquareRight } from "react-icons/pi";
// import styles from "../../components/tradeall.module.css"
import bannerimg2 from "../../assets/forgot_password.png"

const Forgetpassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [isCodesend, setIsCodesend] = useState(false);
  const [Loading, setLoading] = useState(false);
  

  
  const validateEmail = (email) => {
    const emailRegex = /.+@.+/;
    return emailRegex.test(email);
  };


  const handleSubmit = async (e) => {
    
    e.preventDefault();
    if(!email){
      toast.error("Email is required", {
        position: "top-right",
      });
      return;
    }
    setLoading(true)
    setIsCodesend(true);
    if (!validateEmail(email)) {
      toast.error("Invalid email address , @ is mendatory", {
        position: "top-right"});
        setIsCodesend(false);
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/user/forgotPasswordotp`,
        { email },
        {headers: {"Content-Type": "application/json"},
        }
      );
      localStorage.setItem("userEmail", email);
      setLoading(false)
      setIsCodesend(true);
      console.log("response" , response)
      const data = await response.data;
      console.log("data00" , data)
    //   localStorage.setItem("username" , response.data.user.name )
    //   const userregisterId = response.data.user._id          ;
    //   localStorage.setItem("userregisterId" , userregisterId )
    //   localStorage.setItem("usertoken", data.usertoken);
      setEmail("");
      // setPassword("");

      navigate("/user/verifyotp");
    } catch (error) {
      setLoading(false)
      setIsCodesend(false);
      console.error("Error during login:", error);
      toast.error("Error during login! Please check credientials ", {
        position: "top-right",
      });
    }
  };

  return (
    <>
      <section className="bg-gray-50  flex w-[100%] flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row">
        <div className="w-[100%] sm:w-[100%] md:w-[50%]  lg:w-[50%] xl:w-[50%] flex justify-center items-center h-[50vh] sm:h-[50vh] md:h-[100vh] lg:h-[100vh] xl:sm:h-[100vh]">
          <img
            src={bannerimg2}
            alt="bannerimg"
            className="h-[80vh] sm:h-[80vh] md:h-[80vh] lg:h-[80vh] xl:sm:h-[80vh]"
          ></img>
        </div>
        <div className="w-[100%] sm:w-[100%] md:w-[50%]  lg:w-[50%] xl:w-[50%] flex flex-col items-center justify-center px-6 py-8 lg:py-0 bg-[#f1f1f1]">
          <a
            href="/"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 "
          >
            <img className="h-12 w-26" src={logo} alt="logo" />
            
          </a>

          <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-3xl font-bold text-center">Verify Email</h1>

              <p className="text-slate-500 text-center">Enter your Email Address from which 
              you’ve created your account!</p>
              <form onSubmit={handleSubmit} className="my-10">
                <div className="flex flex-col space-y-5">
                  <label htmlFor="email">
                    <p className="font-medium text-slate-700 pb-2 mt-5">
                       Email Address
                    </p>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                      className="w-full py-3  input-field border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                      placeholder="Enter your Email Address"
                    />
                  </label>
                
                   
                   
                  <button
                    type="submit"
                    className="login-btn w-full py-3 font-medium text-white bg-gray-900 hover:bg-gray-700 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center mt-10"
                  >
                    <span>{isCodesend ? "Sending OTP..." : "Send Verification code"}</span>
                    {/* <PiArrowSquareRight className="text-xl" /> */}
                  </button>
                 
                </div>
              </form>
            </div>
          </div>
      





          {
             Loading ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
             <LineWave 
               height="60"
               width="60"
               color="#111827"
               ariaLabel="bars-loading"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
               className=" w-10 h-10 flex items-center justify-center  mt-10 "
             />
           </div> :
            <div>
            
           </div>
          }
      
        </div>


      </section>
    </>
  );
};

export default Forgetpassword;
