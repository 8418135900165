

// <-------------hlo ashi here are the chnges start---------------->
import React, { useState, useEffect } from "react";
import styles from "../../components/tradeall.module.css";
// import * as Icon from "react-icons/fi";
// import Checkbox from "react-custom-checkbox";
import axios from "axios";
import { FiCheckCircle } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Select from 'react-select'

const UserTable = ({ maindata, hideEstimate, clickedon , selectedCountry , selectedImpacts }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  // const [todaytradevalue, settodaytradevalue] = useState([]);

  const [isBrokerStateFetched, setIsBrokerStateFetched] = useState(false);
  const [brokerState, setBrokerState] = useState(false);

  // const [trade, settrade] = useState("");
  // const [trade_grade, settrade_grade] = useState("");
  // const [investing_name, setinvesting_name] = useState("");
  // const [trading_view, settrading_view] = useState("");
  // const [trading_economics, settrading_economics] = useState("");
  // const [movement_currency, setmovement_currency] = useState("");
  // const [movement_correlation, setmovement_correlation] = useState("");
  // const [flat_currency, setflat_currency] = useState("");
  // const [flat_correlation, setflat_correlation] = useState("");


  // const [comments , setcomments] = useState("")
  // const [trade_log , settrade_log] = useState("")
  // const [trade_time , settrade_time] = useState("");
  
  // const [trade_comment , settrade_comment] = useState("")
  const [matcheduserdata , setMatcheduserdata]= useState([])



  const handleEventClick = async (item) => {
    let eventName;
    let eventcountry = item.country;
    let eventimpact = item.impact;
    let eventdate = item.date;
    let eventtime = item.time;
    let timeclickedurl = 1 ;
    // if (item.event.includes("(")) {
    //   eventName = item.event.substring(0, item.event.indexOf("(")).trim();
    // } else {
    //   eventName = item.event;
    // }

    
    const cleanEventName = (event) => {
     
      const monthPattern = /\((Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)(\/\d+)?\)/g;
      
      const cleanedEvent = event
          .replace(monthPattern, "") // Remove parentheses with month names and optional numbers like (aug/11)
          .replace(/\(\s*\)/g, "") 
          .trim();
      return cleanedEvent;
  };

  // Check if event contains parentheses and clean it
  if (item.event.includes("(")) {
      eventName = cleanEventName(item.event);
  } else {
      eventName = item.event;
  }

  console.log("eventName" , eventName)
    if (eventcountry === "UK" || eventcountry === "US" || eventcountry === "CA" ||
    eventcountry === "CH" || eventcountry === "EU" || eventcountry === "JP" ||
    eventcountry === "AU" || eventcountry === "NZ" || eventcountry === "DE" || 
    eventcountry === "IT" || eventcountry === "FR" )

    {
      localStorage.setItem(
        "eventnameandcountry:",
        JSON.stringify({ eventName, eventcountry , eventimpact  , eventdate , eventtime , timeclickedurl })
      );   
      
      const url = new URL(window.location.origin + "/user/eventshistory");
      url.searchParams.set("eventName", eventName);
      url.searchParams.set("eventcountry", eventcountry);
      url.searchParams.set("eventimpact", eventimpact);
      url.searchParams.set("eventtime", eventtime);
      url.searchParams.set("eventdate", eventdate);
      url.searchParams.set("timeclickedurl", timeclickedurl);
     
    
      // Open the new tab with the constructed URL
      const newTab = window.open(url.toString(), "_blank");
      // const newTab = window.open("/admin/eventshistory", "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        alert("Failed to open a new tab.");
      }

      // navigate("/eventshistory");
    } else {
      alert("Sorry, data is available  ");
    }
  };

  const fetchBrokerState = async () => {
    try {
      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
     const userid = localStorage.getItem("userregisterId")
      const response = await axios.get(`${process.env.REACT_APP_URL}/user/checkbrokerstate/${userid}` ,  {headers: h});
      // console.log("response.data.brokerstate" , response.data.brokerstate)
      setBrokerState(response.data.brokerstate); 
      setIsBrokerStateFetched(true);
    } catch (error) {
      console.error('Error fetching broker state:', error);
    }
  };


  useEffect(()=>{
    fetchBrokerState()
  },[brokerState])

  const matchedevents_with_oldtrades = async ()=>{
    try{
      const tokenfromls = localStorage.getItem("usertoken");

      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId")
      // console.log("userid" , userid)
      const response = await axios.get(`${process.env.REACT_APP_URL}/user/matched-events/${userid}` , { headers: h })
      // console.log("response0-----------------" , response.data.matchedEvents)
      setMatcheduserdata(response.data.matchedEvents)
    }
    catch(error){
      console.log("error" , error)
    }
    // const response = axios.get("")
  }

  useEffect(()=>{
    matchedevents_with_oldtrades();
  },[])
  
  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.some((selectedItem) => selectedItem.tradeid === item._id)) {
        return prevSelectedItems.filter((selectedItem) => selectedItem.tradeid !== item._id);
      }
       else {
        return [
          ...prevSelectedItems,
          {
            tradeid: item._id,
            date: item.date,
            time: item.time,
            country: item.country,
            event: item.event,
            currency: item.currency,
            trade : null,
            trade_grade : null , 
            investing_name : null , 
            trading_view : null , 
            trading_economics : null ,
            movement_currency : null ,
            movement_correlation : null ,
            flat_currency: null,
            flat_correlation : null ,
            comments: null ,
            trade_log : null , 
            trade_time : null ,
            trade_comment : null
          },
        ];
      }
    });
  };

// Updated handleSelectChange function
  // const handleSelectChange = (tradeid, field, selected) => {
  //   const value = selected?.value || null; // Get the value from the selected option
  //   setSelectedItems((prevSelectedItems) =>
  //     prevSelectedItems.map((selectedItem) =>
  //       selectedItem.tradeid === tradeid
  //         ? { 
  //           ...selectedItem, 
  //           [field]: value,
  //           investing_name: investing_name || selectedItem.investing_name, // Capture the investing name
  //           trading_view: trading_view || selectedItem.trading_view,
  //           trading_economics: trading_economics || selectedItem.trading_economics,
  //           comments: comments || selectedItem.comments,
  //           trade_time: trade_time || selectedItem.trade_time,
  //           trading_view: trade_comment || selectedItem.trade_comment,
  //           // trading_view: trading_view || selectedItem.trading_view,



  //         } // Assign the extracted value
  //         : selectedItem
  //     )
  //   );
  // };
  const handleSelectChange = (tradeid, field, selected) => {

    const value = selected?.value || null; // Get the value from the selected option
   console.log("value" , value)
   console.log("tradeid" , tradeid)
   console.log("field" , field)
   console.log("selected" , selected)
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.map((selectedItem) =>
        selectedItem.tradeid === tradeid
          ? { 
            ...selectedItem, 
            [field]: value,

            investing_name: field === 'investing_name' ? value : selectedItem.investing_name,
            trading_view: field === 'trading_view' ? value : selectedItem.trading_view,
            trading_economics: field === 'trading_economics' ? value : selectedItem.trading_economics,
            comments: field === 'comments' ? value : selectedItem.comments,
            trade_time: field === 'trade_time' ? value : selectedItem.trade_time,
            trade_comment: field === 'trade_comment' ? value : selectedItem.trade_comment,
          } // Assign the extracted value
          : selectedItem
      )
    );
  };

  const sendSelected_tradevalues = async (e) => {
    e.preventDefault();
    try {


      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId");


      if(selectedItems.length === 0) {
        toast.error("No trade selected yet!", {
          position: "top-right",
        });
      }
      else {

        // const final_selection = {
        //   trade : trade?.value || trade, 
        //   trade_grade  : trade_grade?.value || trade_grade, 
        //   investing_name , 
        //   trading_view, 
        //   trading_economics, 
        //   movement_currency  : movement_currency?.value || movement_currency, 
        //   movement_correlation: movement_correlation?.value || movement_correlation, // Extract 'value'
        //   flat_correlation  : flat_correlation?.value || flat_correlation, 
        //   flat_currency: flat_currency?.value || flat_currency, // Extract 'value'
        //   comments, 
        //   trade_log: trade_log?.value || trade_log, // Extract 'value'
        //   trade_time, 
        //   trade_comment
        // };
        // console.log("selectedItems" , selectedItems)
        const response = await axios.post(`${process.env.REACT_APP_URL}/user/selectedtrade`,
          { selectedItems, userid },{ headers: { "Content-Type": "application/json" , ...h} 
        });
        // console.log("response")
        toast.success("Data has been saved successfully!", {
          position: "top-right",
        });
        // console.log("response of final selection-------------->", response);
      }
     
      

      
    } catch (error) {
      console.error("Error sending selected items:", error);
    }
  };

  const savedtodaydata = async () => {
    try {
      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId");
      const savedresponse = await axios.get(`${process.env.REACT_APP_URL}/user/gettodayselectedtrade/${userid}` ,  {headers: h});
      
      if (savedresponse.status === 200) {
        console.log("savedresponse", savedresponse.data.latestDocument.trades);
        const latestTrades = savedresponse.data.latestDocument.trades;
       
        const formattedTrades = latestTrades.map((trade) => ({
          tradeid: trade.tradeid,
          date: trade.date,
          time: trade.time,
          country: trade.country,
          event: trade.event,
          currency: trade.currency,
          trade: trade.trade,
          trade_grade: trade.trade_grade,
          investing_name: trade.investing_name,
          trading_view : trade.trading_view,
          trading_economics : trade.trading_economics ,
          movement_currency : trade.movement_currency ,
          movement_correlation : trade.movement_correlation ,
          flat_currency: trade.flat_currency,
          flat_correlation : trade.flat_correlation ,
          comments : trade.comments,
          trade_log : trade.trade_log , 
          trade_time : trade.trade_time,
          trade_comment : trade.trade_comment
        }));

        console.log("formattedTrades" , formattedTrades)
        setSelectedItems(formattedTrades);
        // settodaytradevalue(formattedTrades);
        // console.log("selectedid" , formattedTrades.map((trade)=>trade.tradeid))
      } else if (savedresponse.status === 404) {
        toast.error("No data found", { position: "top-right" });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    savedtodaydata();
  }, []);

  const options = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
];
const trade_grades = [
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'c', label: 'C' }
];
  
const movement_currency_option = [
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'c', label: 'C' }
];
  
const movement_correlation_option = [
  { value: 'positive', label: 'Positive' },
  { value: 'negative', label: 'Negative' },
  { value: 'n/a', label: 'N/A' }
];



const flat_currency_option = [
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'c', label: 'C' }
];

const flat_correlation_option = [
  { value: 'positive', label: 'Positive' },
  { value: 'negative', label: 'Negative' },
  { value: 'n/a', label: 'N/A' }
];

const trade_log_option = [
  { value: 'profit', label: 'Profit' },
  { value: 'loss', label: 'Loss' },
  { value: 'n/a', label: 'N/A' }
];
  

  const currentTime = new Date();
  const timePlus30Min = new Date(currentTime.getTime() + 30 * 60 * 1000);
  const formatTime = (date) => {
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };
  const timeString = formatTime(timePlus30Min);
  const todayDate = new Date().toISOString().split("T")[0];
  const formattedDate = todayDate.replace(/-/g, ".");
  




  const getCurrencyOptions = (country) => {
    switch (country) {
      case 'UK':
        return [
          { value: 'GBP/USD', label: 'GBP/USD' },
          { value: 'GBP/JPY', label: 'GBP/JPY' },
          { value: 'GBP/NZD', label: 'GBP/NZD' },
          { value: 'EUR/GBP', label: 'EUR/GBP' },
          { value: 'GBP/CHF', label: 'GBP/CHF' },
          { value: 'GBP/AUD', label: 'GBP/AUD' },
          { value: 'GBP/CAD', label: 'GBP/CAD' },
        ];
      case 'US':
        return [
          { value: "GBP/USD", label: "GBP/USD" },
          { value: "USD/JPY", label: "USD/JPY" },
          { value: "NZD/USD", label: "NZD/USD" },
          { value: "EUR/USD", label: "EUR/USD" },
          { value: "USD/CHF", label: "USD/CHF" },
          { value: "AUD/USD", label: "AUD/USD" },
          { value: "USD/CAD", label: "USD/CAD" },
        ];
      case 'JP':
        return [
          { value: "USD/JPY", label: "USD/JPY" },
          { value: "GBP/JPY", label: "GBP/JPY" },
          { value: "NZD/JPY", label: "NZD/JPY" },
          { value: "EUR/JPY", label: "EUR/JPY" },
          { value: "CHF/JPY", label: "CHF/JPY" },
          { value: "AUD/JPY", label: "AUD/JPY" },
          { value: "CAD/JPY", label: "CAD/JPY" },
        ];
        case 'CA':
        return [
      { value: "USD/CAD", label: "USD/CAD" },
      { value: "CAD/JPY", label: "CAD/JPY" },
      { value: "NZD/CAD", label: "NZD/CAD" },
      { value: "EUR/CAD", label: "EUR/CAD" },
      { value: "CAD/CHF", label: "CAD/CHF" },
      { value: "AUD/CAD", label: "AUD/CAD" },
      { value: "GBP/CAD", label: "GBP/CAD" },
        ];
        case 'CH':
        return [
          { value: "USD/CHF", label: "USD/CHF" },
          { value: "CHF/JPY", label: "CHF/JPY" },
          { value: "NZD/CHF", label: "NZD/CHF" },
          { value: "EUR/CHF", label: "EUR/CHF" },
          { value: "GBP/CHF", label: "GBP/CHF" },
          { value: "AUD/CHF", label: "AUD/CHF" },
          { value: "CAD/CHF", label: "CAD/CHF" },
        ];
        case 'EU':
        case 'DE':
        case 'IT':
        case 'FR':
        return [
          { value: "EUR/USD", label: "EUR/USD" },
          { value: "EUR/JPY", label: "EUR/JPY" },
          { value: "EUR/NZD", label: "EUR/NZD" },
          { value: "EUR/GBP", label: "EUR/GBP" },
          { value: "EUR/CHF", label: "EUR/CHF" },
          { value: "EUR/AUD", label: "EUR/AUD" },
          { value: "EUR/CAD", label: "EUR/CAD" },
        ];
        case 'AU':
        return [
          { value: "AUD/USD", label: "AUD/USD" },
          { value: "AUD/JPY", label: "AUD/JPY" },
          { value: "AUD/NZD", label: "AUD/NZD" },
          { value: "EUR/AUD", label: "EUR/AUD" },
          { value: "AUD/CHF", label: "AUD/CHF" },
          { value: "GBP/AUD", label: "GBP/AUD" },
          { value: "AUD/CAD", label: "AUD/CAD" },
        ];
        case 'NZ':
          return [
      { value: "NZD/USD", label: "NZD/USD" },
      { value: "NZD/JPY", label: "NZD/JPY" },
      { value: "GBP/NZD", label: "GBP/NZD" },
      { value: "EUR/NZD", label: "EUR/NZD" },
      { value: "NZD/CHF", label: "NZD/CHF" },
      { value: "AUD/NZD", label: "AUD/NZD" },
      { value: "NZD/CAD", label: "NZD/CAD" },
          ];
      // Add other cases here for more currencies
      default:
        return [];
    }
  };


  const getflat_CurrencyOptions = (country) => {
    switch (country) {
      case 'UK':
        return [
          { value: 'GBP/USD', label: 'GBP/USD' },
          { value: 'GBP/JPY', label: 'GBP/JPY' },
          { value: 'GBP/NZD', label: 'GBP/NZD' },
          { value: 'EUR/GBP', label: 'EUR/GBP' },
          { value: 'GBP/CHF', label: 'GBP/CHF' },
          { value: 'GBP/AUD', label: 'GBP/AUD' },
          { value: 'GBP/CAD', label: 'GBP/CAD' },
        ];
      case 'US':
        return [
          { value: "GBP/USD", label: "GBP/USD" },
          { value: "USD/JPY", label: "USD/JPY" },
          { value: "NZD/USD", label: "NZD/USD" },
          { value: "EUR/USD", label: "EUR/USD" },
          { value: "USD/CHF", label: "USD/CHF" },
          { value: "AUD/USD", label: "AUD/USD" },
          { value: "USD/CAD", label: "USD/CAD" },
        ];
      case 'JP':
        return [
          { value: "USD/JPY", label: "USD/JPY" },
          { value: "GBP/JPY", label: "GBP/JPY" },
          { value: "NZD/JPY", label: "NZD/JPY" },
          { value: "EUR/JPY", label: "EUR/JPY" },
          { value: "CHF/JPY", label: "CHF/JPY" },
          { value: "AUD/JPY", label: "AUD/JPY" },
          { value: "CAD/JPY", label: "CAD/JPY" },
        ];
        case 'CA':
        return [
      { value: "USD/CAD", label: "USD/CAD" },
      { value: "CAD/JPY", label: "CAD/JPY" },
      { value: "NZD/CAD", label: "NZD/CAD" },
      { value: "EUR/CAD", label: "EUR/CAD" },
      { value: "CAD/CHF", label: "CAD/CHF" },
      { value: "AUD/CAD", label: "AUD/CAD" },
      { value: "GBP/CAD", label: "GBP/CAD" },
        ];
        case 'CH':
        return [
          { value: "USD/CHF", label: "USD/CHF" },
          { value: "CHF/JPY", label: "CHF/JPY" },
          { value: "NZD/CHF", label: "NZD/CHF" },
          { value: "EUR/CHF", label: "EUR/CHF" },
          { value: "GBP/CHF", label: "GBP/CHF" },
          { value: "AUD/CHF", label: "AUD/CHF" },
          { value: "CAD/CHF", label: "CAD/CHF" },
        ];
        case 'EU':
        case 'DE':
        case 'IT':
        case 'FR':
        return [
          { value: "EUR/USD", label: "EUR/USD" },
          { value: "EUR/JPY", label: "EUR/JPY" },
          { value: "EUR/NZD", label: "EUR/NZD" },
          { value: "EUR/GBP", label: "EUR/GBP" },
          { value: "EUR/CHF", label: "EUR/CHF" },
          { value: "EUR/AUD", label: "EUR/AUD" },
          { value: "EUR/CAD", label: "EUR/CAD" },
        ];
        case 'AU':
        return [
          { value: "AUD/USD", label: "AUD/USD" },
          { value: "AUD/JPY", label: "AUD/JPY" },
          { value: "AUD/NZD", label: "AUD/NZD" },
          { value: "EUR/AUD", label: "EUR/AUD" },
          { value: "AUD/CHF", label: "AUD/CHF" },
          { value: "GBP/AUD", label: "GBP/AUD" },
          { value: "AUD/CAD", label: "AUD/CAD" },
        ];
        case 'NZ':
          return [
      { value: "NZD/USD", label: "NZD/USD" },
      { value: "NZD/JPY", label: "NZD/JPY" },
      { value: "GBP/NZD", label: "GBP/NZD" },
      { value: "EUR/NZD", label: "EUR/NZD" },
      { value: "NZD/CHF", label: "NZD/CHF" },
      { value: "AUD/NZD", label: "AUD/NZD" },
      { value: "NZD/CAD", label: "NZD/CAD" },
          ];
      // Add other cases here for more currencies
      default:
        return [];
    }
  };

  return (
    <div className="max-w-[100%] mx-[4%]  mt-[44vh] sm:mt-[40vh] md:mt-[30vh] lg:mt-[30vh] xl:mt-[20vh] ">
    <div className={` flex justify-end items-center mb-1 mt-[38%] sm:mt-[30%] md:mt-[8%] lg:mt-10 xl:mt-0 ${styles.tableheader}`} >
                <h1 className="cursor-pointer font-medium" title="Click here to save your trade" onClick={sendSelected_tradevalues}>Final Submission:</h1>
                <FiCheckCircle className="cursor-pointer" title="Save your data" />
              </div>


      <div className="overflow-x-auto">
      

        <div>
          {maindata.length === 0 ? (
            <div className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl`}>
              Sorry!! Data for this day is not available.
            </div>
          ) : (
            <>
              
              <table className={` ${styles.tableuser} min-w-full border rounded overflow-x-scroll m-auto `}>
                <thead key={2} className="bg-gray-800 text-white">
                  <tr key={1}>
                    <th className="sticky top-0 py-2 px-4">Date</th>
                    <th className="sticky top-0 py-2 px-4">Time(GMT)</th>
                    <th className="sticky top-0 py-2 px-4">Country</th>
                    <th className="sticky top-0 py-2 px-4">Event</th>
                    <th className="sticky top-0 py-2 px-4">Currency</th>
                    <th className="sticky top-0 py-2 px-4">Previous</th>
                    <th className="sticky top-0 py-2 px-4">Estimate</th>
                    <th className="sticky top-0 py-2 px-4">Actual</th>
                    <th className="sticky top-0 py-2 px-4">Impact</th>
                    <th className="sticky top-0 py-2 px-4">Change</th>
                    <th className="sticky top-0 py-2 px-4">Change Percentage</th>
                    <th className="sticky top-0 py-2 px-4">Bookmark</th>
                  
                    <th className="sticky top-0 py-2 px-4">Trade</th>
                    <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                    <th className="sticky top-0 py-2 px-4">Investing.com Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading view Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading Economics Var name</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Comments</th>
                    <th className="sticky top-0 py-2 px-4">Trade log(profit/loss)</th>
                    <th className="sticky top-0 py-2 px-4">Trade in time(seconds)</th>
                    <th className="sticky top-0 py-2 px-4">Trade comment</th>

                  </tr>
                </thead>
                <tbody>
                  {maindata .filter(item =>
                  //  selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true)
                  (selectedCountry.length > 0 ? selectedCountry.includes(item.country) : true) &&
                  // Check if impact is selected or not, and apply the filter
                  (selectedImpacts.length > 0 ? selectedImpacts.includes(item.impact) : true))
                  .map((item) => {
                    if (hideEstimate && (item.estimate === null || item.estimate === "")) {
                      return null;
                    }

                    const itemDate = item.date;
                    const itemTime = item.time;
                    const isDateToday = itemDate === formattedDate;
                    const isTimeWithinThreshold = itemTime <= timeString;
                    const shouldDisable = clickedon === "yesterday" || (isDateToday && isTimeWithinThreshold);
                    const isSelected = selectedItems.some((selectedItem) => selectedItem.tradeid === item._id);
                    return (
                      <tr key={item._id} className="cursor-pointer hover:bg-gray-100 text-center">
                         {/* <td className="py-2 px-4">{item._id}</td> */}
                        <td className="py-2 px-4">{item.date}</td>
                        <td className="py-2 px-4">{item.time}</td>
                        <td className="py-2 px-4">{item.country}</td>
                        {/* <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer">{item.event}</td> */}
                        <td className={`py-2 px-4 ml-[-5px] cursor-pointer ${
                          matcheduserdata.some((event) => event.event === item.event)
                            ? 'text-yellow-500' 
                            : 'text-blue-500' 
                        }`}
                        onClick={() => handleEventClick(item)}
                        >  {item.event} </td>

                        <td className="py-2 px-4">{item.currency}</td>
                        <td className="py-2 px-4">{item.previous}</td>
                        <td className="py-2 px-4">{item.estimate}</td>
                        <td className="py-2 px-4">{item.actual}</td>
                        <td className="py-2 px-4">{item.impact}</td>
                        <td className="py-2 px-4">{item.change}</td>
                        <td className="py-2 px-4">{item.changePercentage}</td>
                        <td className="py-2 px-4 flex items-center justify-center">
                        
                          <input
                            type="checkbox"
                            className="mt-4"
                            onChange={() => handleCheckboxChange(item)}
                            checked={isSelected}
                            disabled={!isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                          />
                        
                        </td>

                        {/* {selectedItems.map((item, index) => */}
                          <>
                        <td className="py-2 px-4 min-w-[140px]"> 
                         
                           <Select options={options} 
                            isDisabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                            // value={trade.find(option => option.value === item._id) || null}
                            // value={options.find(option => option.value === item._id) || null}
                            onChange={(selected) =>  handleSelectChange(item._id, 'trade', selected)} 
                            />
                          </td>

                        <td className="py-2 px-4 min-w-[140px]"> 
                          
                            <Select options={trade_grades} 
                             isDisabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                              // value={trade_grades.find(option => option.value === item.trade_grade) || null}
                          
                             onChange={(selected) =>  handleSelectChange(item._id, 'trade_grade', selected)}
                            />
                         
                          </td>

                          <td className="py-2 px-4 min-w-[140px]"> 
                         
                        <input type="text" name="investing_name" id="investing_name" className="focus:border-0 bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="" required 
                          // onChange={(e) => setinvesting_name(e.target.value)}
                          onChange={(e) => {
                            // console.log("e.target", e.target.value);
                            // console.log("item._id" , item._id)
                             // Log the current value of the input
                            handleSelectChange(item._id, 'investing_name', {value:e.target.value}  );
                          }} 
                          disabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                          />
                          </td>

                          <td className="py-2 px-4 min-w-[140px]"> 
                         
                        <input type="text" name="trading_view" id="trading_view" className="focus:border-0 bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="" required 
                            // onChange={(e) => settrading_view(e.target.value)}
                            onChange={(e) => {
                              // console.log("e.target", e.target.value);
                              // console.log("item._id" , item._id)
                               // Log the current value of the input
                              handleSelectChange(item._id, 'trading_view', {value:e.target.value}  );
                            }} 
                            disabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                          />
                          </td>

                          <td className="py-2 px-4 min-w-[140px]"> 
                         
                        <input type="text" name="trading_economics" id="trading_economics" className="focus:border-0 bg-gray-50 border border-gray-300
                         text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="" required 
                        //  onChange={(e) => settrading_economics(e.target.value)}
                        onChange={(e) => {
                          console.log("e.target", e.target.value); // Log the current value of the input
                          handleSelectChange(item._id, 'trading_economics', { value: e.target.value });
                        }} 
                        disabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                          />
                          </td>

                          <td className="py-2 px-4 min-w-[140px]"> 
                         
                          <Select 
                          // options={movement_currency_option} 
                          options={getCurrencyOptions(item.country)}
                            isDisabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                            // onChange={(selected) => setmovement_currency(selected)} 
                            // value={movement_currency.find(option => option.value === item._id) || null}
                            onChange={(selected) =>  handleSelectChange(item._id, 'movement_currency', selected)}
                          />
                          </td>

                          <td className="py-2 px-4 min-w-[140px]"> 
                         
                          <Select options={movement_correlation_option}  isDisabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                            // onChange={(selected) => setmovement_correlation(selected)} 
                            // value={movement_correlation.find(option => option.value === item._id) || null}
                            onChange={(selected) =>  handleSelectChange(item._id, 'movement_correlation', selected)}
                          />
                          </td>

                          <td className="py-2 px-4 min-w-[140px]"> 
                         
                          <Select 
                          // options={flat_currency_option}
                          options={getflat_CurrencyOptions(item.country)}
                            isDisabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                            // onChange={(selected) => setflat_currency(selected)} 
                            // value={flat_currency.find(option => option.value === item._id) || null}
                            onChange={(selected) =>  handleSelectChange(item._id, 'flat_currency', selected)}
                          />
                          </td>

                          <td className="py-2 px-4 min-w-[140px]"> 
                         
                          <Select options={flat_correlation_option}  isDisabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                            // onChange={(selected) => setflat_correlation(selected)}
                            // value={flat_correlation.find(option => option.value === item._id) || null}
                            onChange={(selected) =>  handleSelectChange(item._id, 'flat_correlation', selected)} 
                          />
                          </td>

                          <td className="py-2 px-4 min-w-[140px]"> 
                         
                         <input type="text" name="comments" id="comments" className="focus:border-0 bg-gray-50 border border-gray-300
                          text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="" required 
                          // onChange={(e) => setcomments(e.target.value)}
                          onChange={(e) => {
                            console.log("e.target", e.target.value); // Log the current value of the input
                            handleSelectChange(item._id, 'comments', { value: e.target.value });
                          }} 
                          disabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                           />
                           </td>
                           
                           <td className="py-2 px-4 min-w-[140px]"> 
                         
                           <Select options={trade_log_option}  isDisabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                            //  onChange={(selected) => settrade_log(selected)}
                            // value={trade_log.find(option => option.value === item._id) || null}
                            onChange={(selected) =>  handleSelectChange(item._id, 'trade_log', selected)} 
                           />
                           </td>

                           <td className="py-2 px-4 min-w-[140px]"> 
                         
                         <input type="text" name="trade_time" id="trade_time" className="focus:border-0 bg-gray-50 border border-gray-300
                          text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="" required 
                            // onChange={(e) => settrade_time(e.target.value)}
                          
                            onChange={(e) => {
                              console.log("e.target", e.target.value); // Log the current value of the input
                              handleSelectChange(item._id, 'trade_time', { value: e.target.value });
                            }}   disabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                           />
                           </td>

                           <td className="py-2 px-4 min-w-[140px]"> 
                         
                         <input type="text" name="trade_comment" id="trade_comment" className="focus:border-0 bg-gray-50 border border-gray-300
                          text-gray-900 text-sm rounded-lg  block w-full p-2.5  " placeholder="" required 
                        //  onChange={(e) => settrade_comment(e.target.value)}
                        onChange={(e) => {
                          console.log("e.target", e.target.value); // Log the current value of the input
                          handleSelectChange(item._id, 'trade_comment', { value: e.target.value });
                        }} 
                        disabled={!isSelected || !isBrokerStateFetched || !brokerState || clickedon === 'yesterday' || shouldDisable}
                           />
                           
                           </td>
                         
                           </>
                            {/* )} */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserTable;

