import React, { useEffect, useState } from 'react';
import styles from "../../components/tradeall.module.css";
import axios from 'axios';
// import { RingLoader } from "react-loader-spinner";
import RingLoader from "react-spinners/RingLoader";

const AllUsersTable = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [Loading, setLoading] = useState(true);
  const getAllUsers = async () => {
    try {

      const tokenfromls = localStorage.getItem("token");
      const h = { authorization: tokenfromls };
      const response = await axios.get(`${process.env.REACT_APP_URL}/user/getusersdata`,  {headers: h});
      setLoading(false)
      console.log("data", response.data);
      setAllUsers(response.data);
    } catch (error) {
      console.log("error", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>

{
  Loading ?  <div className="flex items-center justify-center  mt-10 w-full">
  <RingLoader 
    height="80"
    width="80"
    color="#94A3B8"
    ariaLabel="bars-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    className=" flex items-center justify-center  mt-10 w-[100%]"
  />
</div> 
:
    <div className="container mx-auto mt-[20vh]">
        <div className="overflow-x-auto">
          <table className={`${styles.table} min-w-full border rounded overflow-x-scroll m-auto`}>
            <thead className="bg-gray-800 text-white">
              <tr>
              <th className="sticky top-0 py-2 px-4">S.No.</th>
                <th className="sticky top-0 py-2 px-4">Name</th>
                <th className="sticky top-0 py-2 px-4">Email</th>
                <th className="sticky top-0 py-2 px-4">Phonenumber</th>
                <th className="sticky top-0 py-2 px-4">City</th>
                <th className="sticky top-0 py-2 px-4">Country</th>
                <th className="sticky top-0 py-2 px-4">Broker</th>
                <th className="sticky top-0 py-2 px-4">Broker Id</th>
              </tr>
            </thead>
            <tbody>
              {allUsers.map((item , index) => (
                <tr key={item._id} className=" hover:bg-gray-100 text-center">
                     <td className="py-2 px-4">{index+1}.</td>
                  <td className="py-2 px-4">{item.name}</td>
                  <td className="py-2 px-4">{item.email}</td>
                  <td className="py-2 px-4">{item.phonenumber}</td>
                  <td className="py-2 px-4 ml-[-5px] text-blue-500 ">{item.city}</td>
                  <td className="py-2 px-4">{item.country}</td>
                  <td className="py-2 px-4">{item.broker}</td>
                  <td className="py-2 px-4">{item.brokerid}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
}


    
    </>
  );
};

export default AllUsersTable;
