import React, { useState } from "react";
import { toast } from "react-toastify";
// import { NavLink } from "react-router-dom";
import {LineWave} from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/altrafxlogo_black.png";
// import bannerimg from "../../assets/userbanner.png";
import { useNavigate } from "react-router-dom";
// import logo from "../assets/logo.png";
// import { IoIosEye } from "react-icons/io";
// import { IoIosEyeOff } from "react-icons/io";
import axios from "axios";
// import { PiArrowSquareRight } from "react-icons/pi";
// import styles from "../../components/tradeall.module.css"
import bannerimg2 from "../../assets/forgot_password.png"

const Verifyotp = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [Loading , setLoading]=useState(false)




const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };



  const verifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true)
    const email = localStorage.getItem("userEmail");
    // console.log("email" ,email)

    if (!email) {
        // console.error("Email not found. Please go back and enter your email.");
        toast.error("Email not found. Please enter correct again.");
        return;
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_URL}/user/verifyOTP`,
            { otp , email }  
        );
        setLoading(false)
        // console.log("response" ,response)
        // console.log("otp" ,email)
        // console.log("otp" ,email)
        if (response.status === 200) {
            
            toast.success("OTP verified successfully.");
            navigate("/user/changepassword"); 
        } else if(response.status === 500 || response.status === 401) {
           
            toast.error("Incorrect OTP. Please try again.");
        }
        else{
            console.log("error");
            toast.error("Error verifying OTP");
        }
    } catch (error) {
        setLoading(false)
        console.error("Error verifying OTP:", error);
        toast.error("Error verifying OTP. Please check again .");
    }
};


      // const handleResendCode = async () => {
      //   try {
      //     // Make API request to resend OTP
      //     console.log("Resending OTP...");
      //   } catch (error) {
      //     console.error("Error resending OTP:", error);
      //     // Handle error
      //   }
      // };
      
  return (
    <div>
    

    <section className="bg-gray-50  flex w-[100%] flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row">
        <div className="w-[100%] sm:w-[100%] md:w-[50%]  lg:w-[50%] xl:w-[50%] flex justify-center items-center h-[50vh] sm:h-[50vh] md:h-[100vh] lg:h-[100vh] xl:sm:h-[100vh]">
          <img
            src={bannerimg2}
            alt="bannerimg"
            className="h-[80vh] sm:h-[80vh] md:h-[80vh] lg:h-[80vh] xl:sm:h-[80vh]"
          ></img>
        </div>
        <div className="w-[100%] sm:w-[100%] md:w-[50%]  lg:w-[50%] xl:w-[50%] flex flex-col items-center justify-center px-6 py-8 lg:py-0 bg-[#f1f1f1]">
          <a
            href="/"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 "
          >
            <img className="h-12 w-26" src={logo} alt="logo" />
            
          </a>

          <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-3xl font-bold text-center">Verify Code</h1>

              <p className="text-slate-500 text-center">  Please enter the code we just sent to your email.</p>
              <form  className="my-10" onSubmit={verifyOTP}>
                <div className="flex flex-col space-y-5 justify-center items-center">
                <input
            type="number"
            className="text-center p-3 border-none  border-2 w-[300px] text-[30px] border-black outline-2 outline-black text-black"
            style={{ letterSpacing: 10, border: "none", outline: "none" }}
            placeholder="- - - - - -"
            name=""
            id=""
            // maxLength="4"
            value={otp}
            onChange={handleOtpChange}
          />
                
                   
                   
                  <button
                    type="Verify"
                    className="login-btn w-full py-3 font-medium text-white bg-gray-900 hover:bg-gray-700 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center mt-10"
                  >
                    <span onSubmit={verifyOTP} >Verify OTP</span>
                    {/* <PiArrowSquareRight className="text-xl" /> */}
                  </button>
                 
                </div>
              </form>
            </div>
          </div>
      





          {
             Loading ?  <div className="fixed bottom-0 right-0 mb-2 flex items-end justify-end   w-full">
             <LineWave 
               height="60"
               width="60"
               color="#111827"
               ariaLabel="bars-loading"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
               className=" w-10 h-10 flex items-center justify-center  mt-10 "
             />
           </div> :
            <div>
            
           </div>
          }
      
        </div>


      </section>


    </div>
  )
}

export default Verifyotp
