import React, { useEffect, useState } from "react";
import styles from "../../components/tradeall.module.css";
import axios from "axios";
import UserNavbar from "../../components/user/userNavbar";
import { toast } from "react-toastify";
import RingLoader from "react-spinners/RingLoader";
const Savedtrade = () => {
  const [savedtradedatavalues, setSavedtradedatavalues] = useState([]);
  const [Loading, setLoading] = useState(true);


  
  const savedtradedata = async () => {
    try{
      const tokenfromls = localStorage.getItem("usertoken");
      const h = { authorization: tokenfromls };
      const userid = localStorage.getItem("userregisterId")
    const savedresponse = await axios.get(
      `${process.env.REACT_APP_URL}/user/getsavedtradingdata/${userid}`, {headers: h}
    );
    setLoading(false)
    if (savedresponse.status === 200) {
    console.log("savedresponse", savedresponse.data);
    const latestDocuments = savedresponse.data.latestDocuments;
    let allTrades = [];

    latestDocuments.forEach((doc) => {
      allTrades = allTrades.concat(doc.trades);
    });
    setSavedtradedatavalues(allTrades);
    console.log("allTrades", allTrades);
    }
  
  if(savedresponse.status === 404){
    toast.error("No data found in history.", { position: "top-right" });
    setLoading(false)
    return;
  }
}
    catch(error){
      console.log("error" , error)
      setLoading(false)
    }
  };

  const sortedsavedData = [...savedtradedatavalues].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  useEffect(() => {
    savedtradedata();
  }, []);

  return (
    <>
     
      {
       Loading ?  
       <div className="flex items-center justify-center  mt-10 w-full">
       <RingLoader 
         height="80"
         width="80"
         color="#94A3B8"
         ariaLabel="bars-loading"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         className=" flex items-center justify-center  mt-10 w-[100%]"
       />
     </div>
      : <> 
      <UserNavbar />
      <div className="container mx-auto mt-[10vh]">
      <div className="overflow-x-auto">

      <div className=" mx-auto mt-[8vh]">
        {/* <div className=""> */}
      
            {sortedsavedData.length === 0 ? (
              <div
                className={`${styles.data_missing} m-2 mt-[40%] sm:mt-[40%] md:mt-10 lg:mt-5 xl:mt-5 text-center font-semibold text-xl `}
              >
                Sorry!! you haven't saved any data yet .
              </div>
            ) : (
              <>
              <h1 className="font-medium text-black m-2 text-[18px]">History of selected trades-</h1>
              <table className={` ${styles.tableuser} min-w-full border rounded overflow-x-scroll m-auto `}>
                <thead key={2} className="bg-gray-800   text-white ">
                  <tr key={1}>
                    <th className=" sticky top-0 py-2 px-4">Date</th>
                    <th className=" sticky top-0 py-2 px-4">Time(GMT)</th>
                    <th className="sticky top-0 py-2 px-4">Country</th>
                    <th className="sticky top-0 py-2 px-4">Currency</th>
                    <th className="sticky top-0 py-2 px-4">Event</th>

                    <th className="sticky top-0 py-2 px-4">Trade</th>
                    <th className="sticky top-0 py-2 px-4">Trade Grade</th>
                    <th className="sticky top-0 py-2 px-4">Investing.com Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading view Var name</th>
                    <th className="sticky top-0 py-2 px-4">Trading Economics Var name</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Movement (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Currency)</th>
                    <th className="sticky top-0 py-2 px-4">Flat (Correlation)</th>
                    <th className="sticky top-0 py-2 px-4">Comments</th>
                    <th className="sticky top-0 py-2 px-4">Trade log(profit/loss)</th>
                    <th className="sticky top-0 py-2 px-4">Trade in time(seconds)</th>
                    <th className="sticky top-0 py-2 px-4">Trade comment</th>
                  </tr>
                </thead>

                <tbody>
                  {sortedsavedData.map((item) => (
                    <tr
                      key={item._id} className="cursor-pointer hover:bg-gray-100 text-center" >
                      <td className="py-2 px-4">{item.date}</td>
                      <td className="py-2 px-4">{item.time}</td>
                      <td className="py-2 px-4">{item.country}</td>
                      <td className="py-2 px-4">{item.currency}</td>
                      <td className="py-2 px-4 ml-[-5px] text-blue-500 cursor-pointer">{item.event}</td>
                      

                      <td className="py-2 px-4">{item.trade}</td>
                      <td className="py-2 px-4">{item.trade_grade}</td>
                      <td className="py-2 px-4">{item.investing_name}</td>
                      <td className="py-2 px-4">{item.trading_view}</td>
                      <td className="py-2 px-4">{item.trading_economics}</td>
                      <td className="py-2 px-4">{item.movement_currency}</td>
                      <td className="py-2 px-4">{item.movement_correlation}</td>
                      <td className="py-2 px-4">{item.flat_currency}</td>
                      <td className="py-2 px-4">{item.flat_correlation}</td>
                      <td className="py-2 px-4">{item.comments}</td>
                      <td className="py-2 px-4">{item.trade_log}</td>
                      <td className="py-2 px-4">{item.trade_time}</td>
                      <td className="py-2 px-4">{item.trade_comment}</td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
              </>
            )}
         
        {/* </div> */}
      </div>

      </div>


          </div>
      </>
       }
          
  
     
    </>
  );
};

export default Savedtrade;
